export class Controller3DControl {
    onAdd(map) {
        this.map = map;
        this.container = document.createElement('div');
        const button = document.createElement('button');
        button.setAttribute('type', 'button');
        button.textContent = '3D';
        this.container.classList.add('mapboxgl-ctrl');
        this.container.classList.add('mapboxgl-ctrl-group');
        this.container.classList.add('mapboxgl-ctrl-3dcompass');
        button.addEventListener('click', () => {
            this.is3dActive = !this.is3dActive;
            this.container.classList.toggle('active');
            map.easeTo({
                pitch: (this.is3dActive) ? 60 : 0
            });
        });
        this.container.appendChild(button);
        return this.container;
    }
    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}
