var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MapboxGL from 'mapbox-gl';
import StylesControl from 'mapbox-gl-controls/lib/StylesControl/StylesControl';
import RulerControl from 'mapbox-gl-controls/lib/RulerControl/RulerControl';
import CompassControl from 'mapbox-gl-controls/lib/CompassControl/CompassControl';
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import { Controller3DControl } from './Controller3DControl';
export class MapManager extends Component {
    constructor() {
        super('MapManger', false);
        this.accessToken = 'pk.eyJ1IjoiaGFya2xlIiwiYSI6IjdaV1YwSE0ifQ._e9Yei84vx1UYJGZaFTwKA';
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            this.mapElement = $('#map');
            this.initOptions();
            this.createMap();
            let markers = [];
            $('body').on('map::zoomTo', (event) => {
                if (markers.length > 0)
                    markers.forEach((marker) => marker.remove());
                this.map.flyTo({
                    center: event.detail.latlng,
                    zoom: event.detail.zoom,
                    speed: 1,
                    curve: 1,
                    easing: (t) => t
                });
                // add a marker to the map
                var element = document.createElement('div');
                element.className = 'marker';
                element.style.width = '25px';
                element.style.height = '40px';
                markers.push(new MapboxGL.Marker(element, { anchor: 'bottom' }).setLngLat(event.detail.latlng).addTo(this.map));
            });
            this.success();
        });
    }
    /*
     * Fetches the map options from the data attributes of the map element
     */
    initOptions() {
        this.options = {
            style: this.mapElement.data('style') ? this.mapElement.data('style') : 'full',
            lng: this.mapElement.data('lng') ? +this.mapElement.data('lng') : 138.6,
            lat: this.mapElement.data('lat') ? +this.mapElement.data('lat') : 35.5,
            zoom: this.mapElement.data('zoom') ? +this.mapElement.data('zoom') : 7.5,
            pitch: this.mapElement.data('pitch') ? +this.mapElement.data('pitch') : 0,
            bearing: this.mapElement.data('bearing') ? +this.mapElement.data('bearing') : 0,
            marker: false
        };
        if (this.mapElement.data('marker')) {
            if (this.mapElement.data('marker') === 'true') {
                this.options.marker = true;
            }
            else if (this.mapElement.data('marker') === 'false') {
                this.options.marker = false;
            }
            else {
                this.options.marker = this.mapElement.data('marker');
            }
        }
    }
    createMap() {
        MapboxGL.accessToken = this.accessToken;
        // Initialize the map
        this.map = new MapboxGL.Map({
            container: this.mapElement.get(0),
            style: 'mapbox://styles/harkle/cka5g1z5j12kv1inwfk5ax7nm',
            center: [this.options.lng, this.options.lat],
            zoom: this.options.zoom,
            attributionControl: this.options.style !== 'nude',
            customAttribution: '© Lionel Tardy',
            pitch: this.options.pitch,
            bearing: this.options.bearing,
            scrollZoom: (this.options.style === 'full') ? true : false
        });
        // Set controls
        if (this.options.style !== 'nude')
            this.map.addControl(new CompassControl(), 'bottom-left');
        this.add3DController(this.options.style === 'full' ? 'bottom-left' : 'top-right');
        this.map.addControl(new MapboxGL.NavigationControl({ showCompass: false }), this.options.style === 'full' ? 'bottom-left' : 'top-left');
        if (this.options.style === 'full') {
            if ($('body').hasClass('is-admin')) {
                this.map.addControl(new StylesControl({
                    styles: [
                        {
                            label: 'Neon',
                            styleName: 'kanako-neon',
                            styleUrl: 'mapbox://styles/harkle/cka5g1z5j12kv1inwfk5ax7nm',
                        }, {
                            label: 'Lowkey',
                            styleName: 'kanako-lowtech',
                            styleUrl: 'mapbox://styles/harkle/cka5i3og214m01ipfo9snfvzk',
                        }, {
                            label: 'Classic',
                            styleName: 'kanako-classic',
                            styleUrl: 'mapbox://styles/harkle/cka5fk2el0bcy1iqr7vpjzwzw',
                        }
                    ],
                    onChange: (style) => {
                        setTimeout(() => {
                            this.set3DTerrain(this.map, style.styleName);
                        }, 2000);
                    },
                }), 'bottom-right');
            }
            this.map.addControl(new RulerControl(), 'bottom-right');
        }
        // Enable 3D terrain
        this.map.on('load', () => {
            this.set3DTerrain(this.map);
        });
        // Set map location
        let location = [this.options.lng, this.options.lat];
        // Add a marker to the map
        if (this.options.marker) {
            if (typeof this.options.marker !== 'boolean') {
                const rawLocation = this.options.marker.split(',');
                location = [+rawLocation[0], +rawLocation[1]];
            }
            new MapboxGL.Marker($.parseHTML('<div class="marker"></div>').get(0), { anchor: 'bottom' }).setLngLat(location).addTo(this.map);
        }
        // Console output for debugging
        this.map.on('move', () => {
            if ($('body').hasClass('is-admin'))
                console.log(this.map.getCenter(), this.map.getZoom(), this.map.getBearing(), this.map.getPitch());
        });
    }
    set3DTerrain(map, styleName) {
        if (!map.getSource('mapbox-dem')) {
            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });
        }
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });
        if (styleName == 'kanako-classic') {
            map.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-type': 'atmosphere',
                    'sky-atmosphere-sun': [0.0, 0.0],
                    'sky-atmosphere-sun-intensity': 15
                }
            });
        }
        else if (map.getLayer('sky')) {
            map.removeLayer('sky');
        }
    }
    add3DController(position = 'bottom-left') {
        const controller3DControl = new Controller3DControl();
        this.map.addControl(controller3DControl, position);
        if (this.options.pitch != 0) {
            $(controller3DControl.container).addClass('active');
            controller3DControl.is3dActive = true;
        }
    }
}
