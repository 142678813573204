var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class Search extends Component {
    constructor() {
        super('Search', false);
        this.searchIndex = 0;
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('#search-input').on('keydown', (event, field) => {
                if (['Enter', 'ArrowUp', 'ArrowDown'].includes(event.key))
                    event.preventDefault();
                if (field.val().length < 3)
                    return;
                if (event.key == 'Enter') {
                    this.zoomTo($('#search-results a.is-selected'));
                }
                if (event.key == 'ArrowUp') {
                    $('#search-results a.is-selected').prev().addClass('is-selected').next().removeClass('is-selected');
                }
                if (event.key == 'ArrowDown') {
                    $('#search-results a.is-selected').next().addClass('is-selected').prev().removeClass('is-selected');
                }
            });
            $('#search-input').on('keyup', (event, field) => {
                if (field.val().length < 3 || (['Enter', 'ArrowUp', 'ArrowDown'].includes(event.key)))
                    return;
                // Debounce the search
                if (this.timeout)
                    clearTimeout(this.timeout);
                this.timeout = window.setTimeout(() => {
                    this.search();
                }, 300);
            }).on('focus', () => {
                $('#search-input').val('');
            }).on('blur', () => {
                setTimeout(() => {
                    $('#search-results').addClass('d-none');
                }, 500);
            });
            $('body').on('click', '#search-results a', (event, link) => {
                event.preventDefault();
                console.log(link);
                this.zoomTo(link);
            });
            this.success();
        });
    }
    search() {
        return __awaiter(this, void 0, void 0, function* () {
            this.searchIndex++;
            const response = yield $.httpRequest({
                url: '/search.php',
                type: 'POST',
                body: {
                    index: this.searchIndex,
                    query: $('#search-input').val()
                },
                dataType: 'json'
            });
            if (response.index < this.searchIndex)
                return;
            if (response.results.length > 0) {
                $('#search-results').removeClass('d-none').empty();
            }
            else {
                $('#search-results').addClass('d-none');
            }
            response.results.forEach((result, index) => {
                const item = $.parseHTML('<a href="#">' + result.name + '</a>');
                if (index == 0)
                    item.addClass('is-selected');
                item.data('lat', result.lat);
                item.data('lng', result.lng);
                item.data('zoom', result.zoom);
                $('#search-results').append(item);
            });
        });
    }
    zoomTo(element) {
        if (element.length == 0)
            return;
        $('#search-results').addClass('d-none');
        $('#search-input').val('').get(0).blur();
        const event = new CustomEvent('map::zoomTo', {
            detail: {
                latlng: {
                    lat: element.data('lat'),
                    lng: element.data('lng'),
                },
                zoom: element.data('zoom')
            }
        });
        $('body').get(0).dispatchEvent(event);
    }
}
